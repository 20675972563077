<template>
  <v-container fluid>
    <h1 class="h1">Evoluções / Guia SADT</h1>
    <p></p>
      <div class="d-flex justify-end text-end">
        <v-menu eager offset-x left offset-y bottom v-model="menu">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on">
              mdi-printer
            </v-icon>
          </template>
          <v-container class="container">
            <Evolucoes :evolutions="evolutions" :selectedEvolutions="selectedEvolutions"
            :filtered-data="filteredDatasEvolcuoes" :data_inicio="searchFilters.dateStart"
            :data_fim="searchFilters.dateEnd">
          </Evolucoes>
          <div v-if="filteredDatasTQT.length > 0">
              <TrocaTQT :evolutions="evolutions" :selectedEvolutions="selectedEvolutions"
                :filtered-data="filteredDatasTQT" :data_inicio="searchFilters.dateStart"
                :data_fim="searchFilters.dateEnd">
              </TrocaTQT>
            </div>
            <div v-if="filteredDatasGTT.length > 0">
              <TrocaGTT :evolutions="evolutions" :selectedEvolutions="selectedEvolutions"
                :filtered-data="filteredDatasGTT" :data_inicio="searchFilters.dateStart"
                :data_fim="searchFilters.dateEnd">
              </TrocaGTT>
            </div>
            <GuiaSadt :key="selectedBudgets.join(',')" :selectedBudgets="selectedBudgets"></GuiaSadt>
          </v-container>
        </v-menu>
      </div>
      <div class="mt-4">
      <div class="filters__container">
        <v-autocomplete outlined dense label="Operadora" v-model="searchFilters.operatorId" :items="operators" item-value="id"
          item-text="nome" clearable>
          <template v-slot:item="{ item }">
            <p v-if="item.ativo" lass="font-weight-regular">{{ item.nome }}</p>
            <p v-else class="red--text" lass="font-weight-regular">{{ item.nome }} - "INATIVA"</p>
          </template>
        </v-autocomplete>
        <v-select outlined dense label="Modalidade Atual" v-model="searchFilters.modalities" :items="modalities" item-value="id"
          item-text="nome" clearable></v-select>
        <v-autocomplete outlined dense v-model="searchFilters.patientId" :items="patients" item-value="id" 
        item-text="nome" clearable :rules="requiredField" :loading="loading">
          <template v-slot:label>
            <span>Pacientes <span style="color: red;">*</span></span>
          </template>
          <template v-slot:item="{ item }">
            <p v-if="item.ativo" lass="font-weight-regular">Id: {{item.id}} - {{ item.nome }}</p>
            <p v-else class="red--text" lass="font-weight-regular">Id: {{item.id}} - {{ item.nome }} - "INATIVO"</p>
          </template>
        </v-autocomplete>

        <v-text-field hide-details type="date"  v-model="searchFilters.dateStart" outlined
          dense clearable :rules="requiredField">
          <template v-slot:label>
            <span>Data Inicio 'Evolução' <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
        <v-text-field hide-details type="date" v-model="searchFilters.dateEnd" outlined dense
          clearable :rules="requiredField">
          <template v-slot:label>
            <span>Data Fim 'Evolução' <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
        <!-- <v-select outlined dense label="Orçamento" v-model="filters.guide.value" :items="filteredData" item-value="guia"
          item-text="guia" clearable></v-select> --> 
      </div>
      <v-data-table  :loading="isLoading('get:guides')" :items="filteredData" :search="search" :headers="headers" loading-text="Carregando Informações...."
      item-key="id" :sort-by="['dataInicioEvolucao']" :sort-desc="[true]" class="fixed-header-table" fixed-header  height="800px" :items-per-page="-1"
      :single-expand="singleExpand" :expanded.sync="expanded" show-expand >
      <template v-slot:no-data>
        <v-alert >
          Nenhum dado encontrado. Por favor, ajuste os filtros e clique em PESQUISAR.
        </v-alert>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length" class="py-4 grey lighten-2  justify-center">
          <EvolucaoExpand :evolutions="evolutions" :paciente-id="item.paciente.id" :evolucao-id="item.id" :item="item"/>
        </td>
      </template>

      <template v-slot:top>
        <div class="d-flex  ">
          <v-text-field dense class="mr-2 ml-2" outlined v-model="search" label="Pesquisar" append-icon="mdi-magnify" clearable />
          <!-- <v-btn depressed class="ml-2" size="small" @click="handleSelectAllGuides">Todos orçamentos</v-btn> -->
          <v-btn class="mr-4 elevation-0" text @click="collapseAll"> Recolher Linhas </v-btn>
          <v-btn depressed class="mr-2 ml-2" size="small" @click="handleSelectAllEvolutions">Marcar Todas evoluções</v-btn>
          <v-btn  color="red" class="mr-2 ml-2" text @click="clearFilters"> limpar Campos Pesquisa</v-btn>
          <!-- <v-btn color="gree" class="mr-2 ml-2" elevation="0" @click="atualizar"> <v-icon left>mdi-reload </v-icon>Atualizar</v-btn>      -->
          <v-tooltip top :disabled="isFormValid">
            <template v-slot:activator="{ on, attrs }" >
              <span v-on="on" v-bind="attrs">
                <v-btn color="success" class="mr-2 ml-2" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting" @click="getEvolutions" > Pesquisar </v-btn>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.procedimento`]="{ item }">
          {{ item.procedimento.procedimento.id }} -
          {{ item.procedimento.procedimento.nome }}
        </template>
        <template v-slot:[`item.select_guide`]="{ item }">
          <v-simple-checkbox :value="relatedGuidesSelected(item.guia)" @click="() => onChangeCheckboxGuide(item)" />
        </template>
        <template v-slot:[`item.select_evolution`]="{ item }">
          <v-simple-checkbox :value="isSelected('evolutions', item.id)"
            @click="() => onChangeCheckbox('evolutions', item.id)" />
        </template>
        <template v-slot:[`item.select_guide`]="{ item }">
          <v-simple-checkbox :value="selectedBudgets[0] === item.guia" @click="() => onSelectBudget(item)" />
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import api from "../../http";
import UtilsPDF from '../../service/utilsPDF';
import UtilsFunc from "../../service/utilsFunc";
import Evolucoes from '../relatorios/evolucoes/Evolucoes.vue';
import TrocaTQT from '../relatorios/evolucoes/Troca_TQT.vue';
import TrocaGTT from '../relatorios/evolucoes/Troca_GTT.vue';
import GuiaSadt from '../relatorios/sadt/Sadt.vue';
import EvolucaoExpand from '../evolucoes/EvolucoesExpand.vue';
import "../../assets/css/styles.css";

export default {
  name: "evolutionsGuide",
  components: {
    Evolucoes,
    GuiaSadt,
    EvolucaoExpand,
    TrocaTQT,
    TrocaGTT,
  },
  data: () =>
    UtilsFunc.withCRUDUtils({
      displayDateStart: "",
      displayDateEnd: "",
      operators: [],
      patients: [],
      modalities: [],
      activeFilters: [],
      statusOptions: [
        { label: "Ativo", value: true },
        { label: "Inativo", value: false },
      ],
      evolutions: [],
      selectedEvolutions: [],
      operadoraOptions: [],
      modalidadeOptions: [],
      selectedBudgets: [], 
      attachments: [],
      guides: [],
      menu: false,
      loading: false,
      isSubmitting: false,
      singleExpand: false,
      expanded: [],
      filteredDatasEvolcuoes: [],
      filteredDatasTQT: [],
      filteredDatasGTT: [],
      idTQT: [],
      searchFilters: {
        operatorId: undefined,
        patientStatus: true,
        patientId: undefined,
        guideId: undefined,
        modalities: undefined,
        dateStart: undefined,
        dateEnd: undefined,
      },
      requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
      filters: {
        guide: { value: "", compareType: "equal", prop: "guia" },
      },
      headers: [
        { text: "Operadora", value: "sessao.operadora.nome", width: 120, },
        { text: "ID Paciente", value: "paciente.id" },
        { text: "Paciente", value: "paciente.nome", width: 250 },
        { text: "Modalidade", value: "modalidade.nome" },
        { text: "Procedimento", value: "procedimento.procedimento", width: 250 },
        { text: "Nº Orçamento", value: "guia" },
        { text: "Data Ínicio", value: "dataInicioFormatada" },
        { text: "Data Término", value: "dataFimFormatada" },
        { text: "Id Evolução", value: "id" },
        { text: "Data Evolução", value: "dataInicioEvolucao" },
        { text: "Senha", value: "sessao.senha" },
        { text: "Data Início Senha", value: "sessao.senhaDataInicio" },
        { text: "Data Validade Senha", value: "sessao.senhaDataValidadeFormatada", },
        { text: "Selecionar Guia SADT", value: "select_guide", sortable: false, width: 20 },
        { text: "Selecionar Evolução", value: "select_evolution", sortable: false,width: 20 },
      ],
    }),
  methods: {
    formatTime(value) {
      if (!value) return "";
      let hour = moment(value, "HH:mm").hour();
      return hour >= 0 && hour < 12 ? "MANHÃ" : "TARDE";
    },
    async getEvolutions() {
      const LOADING_NAME = "get:guides";
      this.setLoading(LOADING_NAME);
      // Limpa as seleções de guias e evoluções
      this.selectedBudgets = [];
      this.evolutions = [];
      this.attachments = [];
      this.filteredDatasEvolcuoes = [];
      this.filteredDatasTQT = [];
      this.filteredDatasGTT = [];

      try {
        const { operatorId, patientId, modalities, dateStart, dateEnd } = this.searchFilters;
        if (!patientId || !dateStart || !dateEnd) {
          // Exibe uma mensagem de erro e interrompe a execução do método
          this.$toast.error('Selecione as Datas de Início e Fim da Evolução, além do Paciente para realizar a pesquisa!');
          return;
        }
        //if (!operatorId) return this.$toast.warning('Selecione uma operadora para pesquisar')
        const urlParams = new URLSearchParams({
          atendido: 1,
          assinados: 1,
        });

        if (operatorId) urlParams.append("operadora", operatorId);
        if (patientId) urlParams.append("paciente", patientId);
        if (modalities) urlParams.append("modalidade", modalities);
        if (dateStart)
          urlParams.append(
            "data_inicio",
            moment(dateStart).format("DD/MM/YYYY")
          );
        if (dateEnd)
          urlParams.append("data_fim", moment(dateEnd).format("DD/MM/YYYY"));
        const { data } = await api.get(`/atendimentos/evolucoes/?${urlParams}`);

        this.applyFilters();
        const formatDate = UtilsPDF.formatDate;

        this.tableData = data
        .filter(item => item.ativo === true)
          .map((item) => ({
            ...item,
            dataInicioFormatada: formatDate(item.data_inicio),
            dataFimFormatada: formatDate(item.data_fim),
            dataInicioEvolucao: `${this.formatTimestamp(item.timestamp)} - ${item.hora_inicio ? this.formatTime(item.hora_inicio) : ""
              }`,
            sessao: {
              ...item.sessao,
              senhaDataInicio: formatDate(item.sessao.senha_data_inicio),
              senhaDataValidadeFormatada: formatDate(
                item.sessao.senha_data_validade
              ),
            },
          }))
          .filter((item) => item.assinatura && item.timestamp );
       
          this.filteredDatasEvolcuoes = data.filter(item => item.assinatura !== null && item.ativo === true && !(item.procedimento.procedimento_id === 16 || item.procedimento.procedimento_id === 17));
          this.filteredDatasTQT = data.filter(item => item.procedimento.procedimento_id === 16 && item.ativo && item.assinatura !== null);
          this.idTQT = this.filteredDatasTQT.map(item => item.id)
          this.filteredDatasGTT = data.filter(item => item.procedimento.procedimento_id === 17 && item.ativo && item.assinatura !== null);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getModalities() {
      const LOADING_NAME = "get:modalities";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("uteis/modalidades/");
        this.modalities = data;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getOperators() {
      const LOADING_NAME = "get:operators";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/operadoras/operadoras/?ativo=1");
        this.operators = data
          // .filter((item) => item.ativo);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getPatients() {
      this.loading = true;
      try {
        const { operatorId, modalities } = this.searchFilters;
        let urlParams = new URLSearchParams({ todos: 1 });

        // Adiciona o parâmetro de operadora somente se ele não for indefinido ou vazio
        if (operatorId) {
          urlParams.append("operadora", operatorId);
        }

        // Adiciona o parâmetro de modalidade somente se ele não for indefinido ou vazio
        if (modalities) {
          urlParams.append("modalidade", modalities);
        }

        const { data } = await api.get(`/pacientes/listar/?${urlParams}`);

        // Filtra somente pacientes que possuem operadora se um filtro de operadora for definido
        let filteredPatients = operatorId ? data.filter(item => item.operadora !== null) : data;

        // Ordena os pacientes filtrados alfabeticamente pelo nome
        this.patients = filteredPatients.sort((a, b) =>
          a.nome.localeCompare(b.nome, undefined, { sensitivity: 'base' })
        );

      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    // Para formatar a data da evolução com o dia da semana
    formatTimestamp(timestamp) {
      if (timestamp) {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const weekDay = date.toLocaleDateString("pt-BR", { weekday: "long" });
        return `${day}/${month}/${year} - ${weekDay.charAt(0).toUpperCase() + weekDay.slice(1)
          }`;
      }
      return "";
    },
    clearFilters() {
      Object.keys(this.filters).forEach(
        (prop) => (this.filters[prop].value = "")
      );
      this.activeFilters = [];
      this.searchFilters = {
        operatorId: undefined,
        // patientStatus: true,
        patientId: undefined,
        guideId: undefined,
        modalities: undefined,

      };
      this.patients = [];
      this.getPatients();
      this.tableData = [];
      this.selectedEvolutions = [];
      this.search ='';
    },
    applyFilters() {
      this.isSubmitting = true;
      const filters = JSON.parse(JSON.stringify(this.filters));
      this.activeFilters = Object.values(filters).filter(
        (filter) => filter.value !== ""
      );
      this.isSubmitting = false;
    },
    isSelected(prop, id) {
      return this[prop].includes(id);
    },
    onChangeCheckbox(prop, id, item = null) {
      const isAdding = !this.isSelected(prop, id);

      if (!isAdding && prop === "evolutions") {
        this.attachments = this.attachments.filter(
          (attachmentId) => attachmentId !== id
        );
      }

      if (
        prop === "attachments" &&
        item &&
        !this.evolutions.includes(item.id)
      ) {
        this.$toast.warning("Por favor, selecione a Evolução correspondente.");
        return;
      }

      if (isAdding) {
        this[prop].push(id);
      } else {
        this[prop] = this[prop].filter((item) => item !== id);
      }
    },
    onChangeCheckboxGuide(item) {
      const guideNumber = item.guia; // Este é o número do orçamento que o usuário clicou

      // Filtrar todos os IDs de evoluções que têm o mesmo número de orçamento
      const relatedEvolutionIds = this.tableData
        .filter((row) => row.guia === guideNumber)
        .map((filteredItem) => filteredItem.id);

      // console.log(guideNumber)
      // console.log(relatedEvolutionIds)

      // Verificar se todas as evoluções relacionadas já estão selecionadas
      const allSelected = relatedEvolutionIds.every((id) =>
        this.selectedEvolutions.includes(id)
      );

      if (allSelected) {
        // Se todas já estão selecionadas, desmarque-as
        this.selectedEvolutions = this.selectedEvolutions.filter((id) =>
          !relatedEvolutionIds.includes(id)
        );
      } else {
        // Se não, selecione todas as evoluções relacionadas
        this.selectedEvolutions = [...new Set([
          ...this.selectedEvolutions,
          ...relatedEvolutionIds
        ])];
      }
    },
    handleSelectAllEvolutions() {
      if (this.evolutions.length >= this.tableData.length) {
        this.evolutions = [];
        this.attachments = [];
        this.filteredDatasTQT = [];
        this.filteredDatasGTT = [];
      } else {
        this.evolutions = this.tableData.map((i) => i.id);
      }
    },
    relatedGuidesSelected(guideNumber) {
      const relatedEvolutionIds = this.tableData
        .filter((row) => row.guia === guideNumber)
        .map((filteredItem) => filteredItem.id);

      return relatedEvolutionIds.every((id) =>
        this.selectedEvolutions.includes(id)
      );
    },
    // Este método deve ser chamado quando uma guia é selecionada ou desmarcada
    onSelectBudget(item) {
      const guiaNumber = item.guia;
      if (this.selectedBudgets[0] === guiaNumber) {
        // Se a guia já estiver selecionada, desmarque-a.
        this.selectedBudgets = [];
        this.selectedEvolutions = [];
      } else {
        // Se for uma nova guia, selecione-a e marque todas as linhas correspondentes.
        this.selectedBudgets = [guiaNumber];
        this.selectedEvolutions = this.tableData
          .filter((row) => row.guia === guiaNumber)
          .map((filteredItem) => filteredItem.id);
      }
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
    atualizar() {
      this.search = "";
      this.clearFilters();
      this.getOperators();
      this.getModalities();
      this.getPatients();
      this.evolutions = [];
      this.selectedEvolutions = [];
      this.selectedBudgets = []; 
      this.search = '';
    },
    collapseAll() {
      this.expanded = [];
    },
  },
  watch: {
      "searchFilters.operatorId": function () {
      this.getPatients();
    },
    "searchFilters.modalities": function () {
      this.getPatients();
    },
  },
  mounted() {
    this.getOperators();
    this.getModalities();
    this.getPatients();
  },
  computed: {
    filteredData() {
      const filterBy = UtilsFunc.createFilter(this.activeFilters);
      return this.tableData.filter(filterBy);
    },
    isFormValid() {
      // Verifica se todos os campos obrigatórios estão preenchidos para poder habilitar o botão de Salvar
      return !!( this.searchFilters.patientId && 
        this.searchFilters.dateStart && 
        this.searchFilters.dateEnd);
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
  },
};
</script>

<style scoped>
div.filters__container {
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  padding: 0 0.5rem;
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  gap: 0.2rem
}

.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>